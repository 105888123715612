@tailwind base;
@tailwind components;
@tailwind utilities;

/* Roboto, lato, playfair display, maven pro fonts */
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Lora:wght@400;500;600;700&family=Maven+Pro:wght@400;500;600;700;800;900&family=Oxygen:wght@300;400;700&family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap"); /* font-family: 'Lato', sans-serif;

    font-family: 'Lora', serif;

    font-family: 'Maven Pro', sans-serif;

    font-family: 'Playfair Display', serif;

    font-family: 'Oxygen', sans-serif;
        
    font-family: 'Roboto', sans-serif; */

/* Post fonts */
@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:ital,wght@0,300;0,400;0,600;1,300&family=Merriweather:wght@300;400;700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400&family=Raleway:wght@200;300;400;500;600&display=swap");

/* font-family: 'Merriweather', serif;

    font-family: 'Merriweather Sans', sans-serif;

    font-family: 'Open Sans', sans-serif;

    font-family: 'Raleway', sans-serif; */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Oxygen", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Playfair Display", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

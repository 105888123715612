.error-msg {
  background-color: rgba(255, 109, 109, 0.498);
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.success-msg {
  background-color: rgba(109, 255, 138, 0.498);
  width: 100%;
  padding: 10px 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.alert {
  background-color: rgb(255, 116, 116);
}

.notification-toast {
  height: auto;
  position: fixed;
  width: fit-content;
  top: 13vh;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  background-color: rgb(147, 215, 147);
  z-index: 1000;
  transition: ease-in-out 2s;
}
